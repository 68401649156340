<!-- Component is only used for the custom directive of processLinks -->
<!-- Reason: The directive 'processLinks' needs to utilize NuxtLink using '' defineAsyncComponent', and you cannot use that method with NuxtLink -->
<template>  
  <NuxtLink v-if="isExternal" :to="href" :target="target" external>{{ innerText }}</NuxtLink> 
  <NuxtLink v-else :to="href" :target="target" :rel="rel" >{{ innerText }}</NuxtLink>
</template>

<script setup>
import { defineProps } from 'vue';

const props = defineProps({
  href: {
    type: String,
    required: true
  },
  innerText: {
    type: String,
    required: true
  },
  target: {
    type: String,
    default: '_self'
  },
  rel: {
    type: String,
    default: 'noopener noreferrer'
  }
});

// NuxtLink directly assigns 'external' prop to the component if is uses absolute URL and a target prop is given. We add this just to make sure.
const isExternal = computed(() => {
  if (!props.href) {
    return false;
  }

  // Check if link doesn't have same domain
  const url = new URL(props.href, window.location.origin);
  return url.origin !== window.location.origin;
});

</script>
