import validate from "/home/runner/work/web/web/website/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45global from "/home/runner/work/web/web/website/middleware/01.global.js";
import i18n_45global from "/home/runner/work/web/web/website/middleware/i18n.global.js";
import manifest_45route_45rule from "/home/runner/work/web/web/website/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45global,
  i18n_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}