<template>
  <div class="dark:bg-transparent dark:hover:bg-zinc-800 rounded-xl border-gray-300 shadow">
    <div @click="openProduct(activity)" class="cursor-pointer">
      <div
        class="w-[210px] h-[300px] md:w-[280px] md:h-[310px] border border-gray-200 dark:border-zinc-600 overflow-hidden rounded-xl"
      >
        <div class="">
          <SmartImg
            class="w-full h-36 object-cover rounded bg-gray-300 dark:bg-gray-600"
            :src="activity.thumb"
            sizes="(max-width: 767px) 210px, 280px"
          ></SmartImg>
        </div>
        <div class="h-1/2 flex flex-col justify-between px-2 md:pt-4 md:px-4">
          <h3
            class="h-full text-md md:text-xl font-bold text-zinc-900 dark:text-white whitespace-normal break-words line-clamp-1 md:line-clamp-3 leading-6 overflow-hidden mt-2 md:mt-0"
          >
            {{ activity.name }}
          </h3>

            <div class="h-fit grid grid-cols-2 grid-rows-2">
              <StarRating
                class="text-md col-span-2"
                v-if="activity.reviews_count"
                :reviews_count="activity.reviews_count"
                :filled_stars="filledStars"
                :has_half_star="hasHalfStar"
                :rating="activity.reviews_avg"
              />
              <div v-if="parseInt(duration.split(' ')[0]) > 0" class="flex items-end text-sm">
                <span class="flex item-center gap-2">
                <img
                  src="/img/icons/schedule.svg"
                  class="dark:filter dark:invert dark:invert-1"
                  style="font-size: 1.2rem"
                />
                <span>{{ duration }}</span>
                </span>
              </div>
              <div class="flex items-end justify-end space-x-2 col-start-2">
                <!-- <div class="text-zinc-600 dark:text-zinc-400 hidden md:block">{{ $t('From') }}</div> -->
                <div v-if="activityPrice > 0" class="text-sm md:text-2xl font-semibold text-zinc-900 dark:text-white">
                  {{ localizedNumber(activityPrice, 'currency', { maximumFractionDigits: 0 }) }}
                </div>
                <div
                  v-else
                  class="pr-2 pb-2 text-right w-full col-start-2 text-sm font-semibold text-zinc-900 dark:text-white leading-tight md:text-2xl md:p-0"
                >
                  {{ $t('Free') }}
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import SmartImg from '~/components/SmartImg.vue';
import { formatHours, openProduct } from './utils';
const { t } = useI18n();

const props = defineProps({
  activity: {
    type: Object,
    required: true,
  },
});

const duration = computed(() => formatHours(props.activity.duration, t));

const activityPrice = computed(() => {
  if (props.activity.price) {
    return props.activity.price / 100;
  }
  return 0;
});

const filledStars = computed(() => Math.floor(props.activity.reviews_avg));
const hasHalfStar = computed(() => props.activity.reviews_avg - filledStars.value >= 0.5);
</script>

<style>
.material-icons {
  font-family: 'Material Icons';
  font-size: 24px;
  line-height: 1;
  vertical-align: middle;
}
</style>
