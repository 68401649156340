export default defineNuxtRouteMiddleware(async (to, from) => {
  // // Only handle client-side navigation
  // if (import.meta.server) return;

  // console.log(to, from);
  // // Dont redirect if the paths are same. Guards against infinite redirect loops
  // if (to.path === from.path) return abortNavigation();

  // const path = to.path;
  // const lang = to.query.lang || 'en';

  // console.log('Client middleware called for path:', path);
  // try {
  //   const redirectTo = await getRedirect(path, lang);
  // } catch (error) {
  //   console.error('Error fetching redirect:', error);
  //   return abortNavigation();
  // }
  // if (redirectTo) {
  //   console.log('Redirect to:', redirectTo);
  //   return navigateTo(redirectTo);
  // }

  // // Important, protects against an infinite redirect loop
  // if (path == '/') {
  //   return abortNavigation();
  // }
});
