<template>
  <div class="p-6 space-y-6 bg-white dark:bg-zinc-800 rounded-xl">
    <div v-if="!showOnly || showOnly === 'currency'" class="space-y-4">
      <div class="flex items-center space-x-3">
        <img src="/img/icons/universal_currency_alt.svg" class="w-6 h-6 dark:invert" alt="" />
        <h2 class="text-xl font-bold text-gray-900 dark:text-white">{{ $t('Currency') }}</h2>
      </div>
      <div class="relative">
        <select
          ref="currencyEle"
          v-model="selectedCurrency"
          @change="updateCurrency"
          class="w-full appearance-none bg-gray-50 dark:bg-zinc-700 border border-gray-300 dark:border-gray-600 text-gray-900 dark:text-white text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 p-4 pr-10"
        >
          <optgroup label="Popular Currencies" class="font-semibold">
            <option v-for="currency in availableCurrencies.priorityList" :key="currency" :value="currency[0]">
              {{ currency[0] }} -
              {{ currency[1] }}
            </option>
          </optgroup>
          <optgroup label="All Currencies" class="font-normal">
            <option v-for="currency in availableCurrencies.currencyList" :key="currency" :value="currency[0]">
              {{ currency[0] }} -
              {{ currency[1] }}
            </option>
          </optgroup>
        </select>
        <div
          class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-3 text-gray-700 dark:text-gray-300"
        >
          <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
          </svg>
        </div>
      </div>
    </div>

    <div v-if="!showOnly || showOnly === 'language'" class="space-y-4">
      <div class="flex items-center space-x-3">
        <img src="/img/icons/translate.svg" class="w-6 h-6 dark:invert" alt="" />
        <h2 class="text-xl font-bold text-gray-900 dark:text-white">{{ $t('Language') }}</h2>
      </div>
      <div class="relative">
        <select
          ref="languageEle"
          v-model="selectedLanguage"
          @change="updateLocale"
          class="w-full appearance-none bg-gray-50 dark:bg-zinc-700 border border-gray-300 dark:border-gray-600 text-gray-900 dark:text-white text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 p-4 pr-10"
        >
          <option v-for="language in languages" :key="language.code" :value="language.code">
            {{ language.name }}
          </option>
        </select>
        <div
          class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-3 text-gray-700 dark:text-gray-300"
        >
          <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Cookies from 'js-cookie';
import { computed, defineProps, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { defaultNumberLocales } from '~/components/utils';

const props = defineProps({
  // Used to display only one selection
  showOnly: {
    type: String,
    default: null,
    validator: (value) => {
      const validOptions = [null, 'currency', 'language'];
      return validOptions.includes(value);
    },
  },
  // Used to auto open the selection. Mainly for mobile.
  autoOpen: {
    type: Boolean,
    default: false,
  },
});

const { setLocale, locales, numberFormats, locale } = useI18n();

const selectedCurrency = ref(null);
const selectedLanguage = ref(null);

// Refs for the HTML selections
const currencyEle = ref(null);
const languageEle = ref(null);

const emit = defineEmits(['settingsUpdated']);

const updateCurrency = () => {
  const languageLocale = selectedLanguage.value;
  const numberLocale = getNumberLocaleForCurrencyAndLanguage(numberFormats, languageLocale, selectedCurrency.value);
  if (numberLocale) {
    Cookies.set('preferredNumberLocale', numberLocale, { expires: 365 });
    window.location.reload();
  }
  // Emit an event to notify the parent component that settings have been updated
  emit('settingsUpdated');
};

const updateLocale = () => {
  const languageLocale = selectedLanguage.value;

  // setLocale(languageLocale);
  Cookies.set('preferredLanguageLocale', languageLocale, { expires: 365 });

  // Log all hreflang links for debugging
  const allHreflangLinks = document.querySelectorAll('link[rel="alternate"][hreflang]');
  // Find the appropriate hreflang link
  const hreflangLink = document.querySelector(`link[rel="alternate"][hreflang="${languageLocale}"]`);

  if (hreflangLink) {
    //Navigate to the hreflang link
    //Remove the hostname from the href
    const href = hreflangLink.href.replace(window.location.origin, '');
    window.location.href = href;
  } else {
    window.location.href = '/';
  }
  // Emit an event to notify the parent component that settings have been updated
  emit('settingsUpdated');
};

const languages = computed(() => {
  return locales.value.map((locale) => ({
    code: locale.code,
    name: locale.name,
  }));
});

const availableCurrencies = computed(() => {
  let priorityList = [
    ['EUR', 'Euro'],
    ['USD', 'United States Dollar'],
    ['GBP', 'Great Britain Pound'],
    ['AUD', 'Australian Dollar'],
  ];

  let currencyList = [];
  for (const key of Object.keys(numberFormats.value)) {
    const currency = [numberFormats.value[key].currency.currency, numberFormats.value[key].currency.name];

    // Using JSON.stringify so we can compare the arrays
    if (
      currencyList.some((c) => JSON.stringify(c) === JSON.stringify(currency)) ||
      priorityList.some((c) => JSON.stringify(c) === JSON.stringify(currency))
    )
      continue;

    currencyList.push(currency);
  }

  // Sorting the currencyList and priorityList alphabetically
  currencyList.sort((a, b) => a[0].localeCompare(b[0]));
  priorityList.sort((a, b) => a[0].localeCompare(b[0]));

  return { currencyList, priorityList };
});

const getNumberLocaleForCurrencyAndLanguage = (numberFormats, languageLocale, currency) => {
  const languageCode = languageLocale.split('-')[0];
  return Object.keys(numberFormats.value).find(
    (locale) => locale.startsWith(languageCode) && numberFormats.value[locale].currency.currency === currency,
  );
};
const preferredNumberLocaleCookie = useCookie('preferredNumberLocale');
const preferredNumberLocale = computed(() => preferredNumberLocaleCookie.value || locale.value);

onMounted(() => {
  //Find the number format for the current locale
  const numberLocale = preferredNumberLocale.value;
  let numberFormat = numberFormats.value[numberLocale];
  if (!numberFormat) {
    numberFormat = numberFormats.value[defaultNumberLocales[numberLocale] || defaultNumberLocales['en']];
  }
  const selectedFormat = numberFormat ?? numberFormats['en-US'];
  selectedLanguage.value = locale.value;
  selectedCurrency.value = selectedFormat?.currency?.currency ?? 'USD';

  // Auto open selection, if true
  if (props.autoOpen && props.showOnly) {
    const event = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    props.showOnly == 'currency' ? currencyEle.value.dispatchEvent(event) : languageEle.value.dispatchEvent(event);
  }
});
</script>

<style scoped>
.select-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
}

.select-class {
  appearance: none;
}

.custom-arrow {
  position: absolute;
  right: 1em;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

select {
  background-image: none;
}

/* Custom styles for the optgroup */
select optgroup {
  font-weight: bold;
  color: #4a5568;
  background-color: #f7fafc;
  padding: 8px;
}

select optgroup option {
  font-weight: normal;
  padding: 8px;
}

/* Dark mode styles */
.dark select optgroup {
  color: #e2e8f0;
  background-color: #2d3748;
}

/* Separator line */
select optgroup:not(:first-of-type) {
  border-top: 1px solid #e2e8f0;
}

.dark select optgroup:not(:first-of-type) {
  border-top-color: #4a5568;
}
</style>
