<template>
  <div class="bg-gradient-to-r from-ts_blue_to to-ts_blue_from rounded-lg shadow-lg overflow-hidden my-32 mx-4 ">
    <div class="px-6 py-12 md:px-12 md:py-16 flex flex-col md:flex-row items-center justify-between">
      <div class="text-center md:text-left mb-8 md:mb-0 md:mr-8 md:w-1/2">
        <h2 class="text-3xl md:text-4xl font-bold text-white mb-4">
          {{ $t('Join our newsletter') }}
        </h2>
        <p class="text-indigo-100 text-lg md:text-xl max-w-md mx-auto md:mx-0">
          {{ $t('We’ll send you a nice newsletter once per week. No spam.') }}
        </p>
      </div>
      
      <div class="w-full md:w-1/2">
        <form @submit.prevent="handleEmailSubscribe" class="flex flex-col sm:flex-row items-center">
          <div class="relative w-full mb-4 sm:mb-0 sm:mr-4">
            <input
              type="email"
              v-model="email"
              :placeholder="$t('Enter your email')"
              class="w-full px-6 py-4 bg-white bg-opacity-20 text-white placeholder:text-white rounded-full focus:outline-none focus:ring-2 focus:ring-white focus:bg-opacity-30 transition duration-300 ease-in-out"
              required
            />
          </div>
          <button
            :disabled="loading"
            type="submit"
            class="w-full sm:w-auto px-8 py-4 bg-white text-Tourscanner_blue-700 font-semibold rounded-full hover:bg-indigo-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white transition duration-300 ease-in-out"
          >
            <span v-if="!loading">{{ $t('Subscribe') }}</span>
            <span v-else class="flex items-center justify-center">
              <img src="/img/Loading_2.gif" class="h-5 w-5 mr-2" />
              {{ $t('Subscribing') }}
            </span>
          </button>
        </form>
        
        <transition name="fade">
          <div
            v-if="errors"
            class="mt-4 p-3 text-md text-red-800 bg-red-100 rounded-lg"
          >
            {{ errors }}
          </div>
        </transition>
        <transition name="fade">
          <div
            v-if="showSuccess"
            class="mt-4 p-3 text-md text-green-800 bg-green-100 rounded-lg"
          >
            {{ $t('Thank you for subscribing!') }}
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { handleApiResponse } from '~/server/utils/utils';

const loading = ref(false);
const email = ref('');
const errors = ref(null);
const showSuccess = ref(false);

const isValidEmail = (email) => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email);
};

async function handleEmailSubscribe() {
  errors.value = null;
  showSuccess.value = false;

  if (email.value.trim() === '') {
    errors.value = $t('Email is required');
    return;
  }

  if (!isValidEmail(email.value)) {
    errors.value = $t('Please enter a valid email address');
    return;
  }

  loading.value = true;
  errors.value = null;

  const { data, error } = await handleApiResponse('/api/subscribe', {
    method: 'POST',
    body: { email: email.value },
  });

  loading.value = false;

  if (error.value) {
    errors.value = error?.value?.statusMessage === "Member Exists" ? 'Email already subscribed!' : 'Failed to subscribe. Try again later.';
    return;
  }

  if (data) {
    showSuccess.value = true;
    return;
  }
}
</script>
