<template>
  <NuxtLinkLocale v-if="identity.number_of_activities > 2" :to="identity.link || identity.url">
    <div class="rounded-lg overflow-hidden relative h-full">
      <SmartImg
        class="w-[280px] h-[210px] md:w-[270px] md:h-[250px] object-cover bg-gray-400 dark:bg-gray-800"
        :src="identity.featured_image"
        :alt="identity['label']"
        sizes="(max-width: 767px) 280px, 300px"
      />

      <div
        class="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black to-transparent text-white text-left py-0 md:py-1 px-2"
      >
        <div class="flex gap-1">
          <div
            class="bg-Tourscanner_blue-700 dark:bg-Tourscanner_blue-800 px-3 rounded-full py-0 text-md"
          >
            {{ identity.number_of_activities }} {{ $t('Tours') }}
          </div>
          <!-- <div v-if="identity.lastViewedDate" class="tag last-seen">{{ formattedLastSeenDate }}</div> -->
        </div>
        <h3
          class="py-3 px-2 font-semibold text-white dark:text-white whitespace-normal break-words line-clamp-2 md:line-clamp-none leading-5 text-2xl"
        >
          {{ identity.label || identity.name_alias }}
        </h3>
      </div>
    </div>
  </NuxtLinkLocale>
</template>

<script setup>
import SmartImg from '~/components/SmartImg.vue';
import { getRelativeTimeString } from './utils';
const props = defineProps({
  identity: {
    type: Object,
    required: true,
  },
});

const formattedLastSeenDate = computed(() => {
  if (!props.identity.lastViewedDate) return '';

  const date = new Date(props.identity.lastViewedDate);
  return getRelativeTimeString(date);
});
</script>

<style scoped>
/* Ensure the image covers the entire div and the height is smaller */
.max-w-sm {
  max-width: 10rem;
}

.bg-gradient-to-t {
  background: linear-gradient(to top, black, transparent);
}
</style>
